body {
  font-family:PPFragment-SansRegular;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  overflow-x: hidden;
}
ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
li {
  list-style-type: none;
}
.is-relative {
  position: relative;
}
.is-flex{
  display: flex;
}
/*
|------------------------------------------------------
|  Typograph
|------------------------------------------------------
*/
@font-face {
  font-family: PPFragment-SansRegular;
  src: url(font/PPFragment-SansRegular.otf);
}
h1 {
  font-family:PPFragment-SansRegular;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;

}
/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1900px) {
  body {
    font-size: clamp(16px,1vw,18px);
  }
  h1 {
    font-size: clamp(16px,0.8vw,18px);
    line-height: clamp(21px,1vw, 24px);
  }
  
}